/*
  Can be used by react-hook-forms to add single date selector with the default calendar icon
*/
/* eslint-disable @willow/import-date-picker */
import { CalendarOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { get } from 'lodash';
import { ReactNode } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Control, FieldPathByValue, FieldValues, useController } from 'react-hook-form';

import { formatDateFromDate } from '@willow/shared-iso';

import { Hint } from '../Hint';

import 'react-datepicker/dist/react-datepicker.css';

interface Props<T extends FieldValues> {
  fieldName: FieldPathByValue<T, Date | undefined | null>;
  control: Control<T>;
  props?: Omit<ReactDatePickerProps, 'onChange'>;
  handleOnChange?: (e: Date | null) => void;
  hideErrorMessage?: boolean;
  label?: ReactNode;
  hint?: ReactNode;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  displayValue?: boolean;
  showMonthYearPicker?: boolean;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  tooltip?: string;
}

export function ControlledSingleDatePicker<T extends FieldValues>({
  fieldName,
  control,
  props,
  handleOnChange,
  hideErrorMessage = false,
  label,
  hint,
  required,
  placeholder = 'Select Date',
  disabled,
  displayValue,
  showMonthYearPicker = false,
  showMonthDropdown = false,
  showYearDropdown = false,
  minDate,
  maxDate,
  className,
  tooltip,
}: Props<T>) {
  const {
    field,
    formState: { errors },
  } = useController({ control, name: fieldName });
  return (
    <>
      {label && (
        <Form.Label className="u-fs-2 fw-bold">
          {label}
          {required && <span className="u-color-red1 ps-1 ">*</span>}
        </Form.Label>
      )}
      <div className="d-flex align-items-center">
        {displayValue ? (
          <div className="u-fs-2">{field.value ? formatDateFromDate(field.value) : 'N/A'}</div>
        ) : (
          <>
            <DatePicker
              className={classNames('form-control', className, {
                'u-border-color-red1': !!get(errors, fieldName),
              })}
              popperClassName="u-z-200"
              wrapperClassName="w-100"
              name={fieldName}
              selected={field.value}
              onChange={(date) => {
                field.onChange({
                  target: {
                    name: fieldName,
                    value: date,
                  },
                });

                if (handleOnChange) handleOnChange(date);
              }}
              placeholderText={placeholder}
              disabled={disabled}
              showMonthYearPicker={showMonthYearPicker}
              showMonthDropdown={showMonthDropdown}
              showYearDropdown={showYearDropdown}
              autoComplete="off"
              minDate={minDate}
              maxDate={maxDate}
              {...props}
            />

            <CalendarOutlined className="ms-n4 pe-2 u-z-0 u-pointer-events-none" />
            {tooltip && (
              <OverlayTrigger trigger={['click', 'focus', 'hover']} rootClose overlay={<Tooltip>{tooltip}</Tooltip>}>
                <InfoCircleOutlined className="ms-2" />
              </OverlayTrigger>
            )}
          </>
        )}
      </div>
      {hint && <Hint>{hint}</Hint>}
      {!hideErrorMessage && (
        <ErrorMessage
          errors={errors}
          name={fieldName as any} // This *should* work without casting but it doesn't
          render={({ message }) => <div className="u-color-red1 u-fs-2 mt-1">{message}</div>}
        />
      )}
    </>
  );
}

import { z } from 'zod';

import { Address } from './Address';
import { BorrowerId, PortalUserId } from './BrandedIds';
import { Phone } from './Phone';
import { DayAndTimeRange } from './Time';

export const FicoScore = z.number().int().min(300).max(850); // credit rating 300-850
export type FicoScore = z.infer<typeof FicoScore>;

export const CitizenshipStatus = z.enum([
  'usCitizen',
  'usNational',
  'lawfulPermanentResident',
  'conditionalPermanentResident',
  'refugee',
  'asylee',
  'unknown',
]);
export type CitizenshipStatus = z.infer<typeof CitizenshipStatus>;

export const Gender = z.enum(['male', 'female', 'nonBinary', 'preferNotToSay', 'other']);
export type Gender = z.infer<typeof Gender>;

export const Ethnicity = z.enum([
  'white',
  'black',
  'asian',
  'hispanic',
  'nativeAmerican',
  'pacificIslander',
  'middleEastern',
  'multiRacial',
  'other',
  'preferNotToSay',
]);
export type Ethnicity = z.infer<typeof Ethnicity>;

export const BorrowerName = z
  .object({
    firstName: z.string(),
    middleName: z.string().optional(),
    lastName: z.string().optional(),
  })
  .strict();
export type BorrowerName = z.infer<typeof BorrowerName>;

export const BorrowerPreferences = z.object({ contactTimes: z.array(DayAndTimeRange).optional() });
export type BorrowerPreferences = z.infer<typeof BorrowerPreferences>;

export interface BorrowerCompanySettings {
  emailConsent: boolean;
  phoneConsent: boolean;
  emailNotificationsEnabled: boolean;
  paperNotificationsEnabled: boolean;
  mailingAddress?: Address;
  preferences?: BorrowerPreferences;
  citizenshipStatus?: CitizenshipStatus;
  scraFlag?: boolean; // Servicemembers Civil Relief Act (SCRA). Indicates borrower is military and has extra loan protections in cases of financial hardship
  vip?: boolean; // company flag to pay extra attention to borrower
  ficoScore?: FicoScore;
  dob?: Date;
  gender?: Gender;
  ethnicity?: Ethnicity;
}

export interface Borrower extends BaseBorrowerAndPortalUser {
  id: BorrowerId;
  portalUserId?: PortalUserId;
  email?: string;
  ssn?: string;
  settings: BorrowerCompanySettings;
}

export interface BaseBorrowerAndPortalUser {
  phoneNumbers: Phone;
  name: string;
  raw: BorrowerName;
}

const BorrowerEmailConsentActor = z.union([z.literal('lender'), z.literal('borrower'), z.literal('internal')]);
export type BorrowerEmailConsentActor = z.infer<typeof BorrowerEmailConsentActor>;

export const BorrowerEmailConsent = z.object({
  emailConsent: z.boolean(),
  actor: BorrowerEmailConsentActor,
  borrowerIp: z.string().optional(),
});
export type BorrowerEmailConsent = z.infer<typeof BorrowerEmailConsent>;

export const DEFAULT_BORROWER_EMAIL_CONSENT: BorrowerEmailConsent = {
  emailConsent: true,
  actor: 'internal',
} as const;

import * as z from 'zod';

import { CompanyWireSettingsId, PayoffId, PropertyId } from '../BrandedIds';
import { InterestAttribution } from '../PaymentDue';
import { zodBrandedUuid, zodDateOrString } from '../utils/Zod';
import { Fee } from './Charges';
import { LoanStatusActive, LoanStatusForeclosure, LoanStatusPendingTransfer, LoanStatusPreActive } from './LoanStatus';
import { PaymentMethod } from './Payments';

/* TRACKED payoffs */
// Record added to payoff at time status is updated to PROCESSED (aka payoff is recorded)
// This will be the data used for the transaction and balances
export const TrackedPayoffAllowedPaymentMethods = z.enum([
  PaymentMethod.Enum.wire,
  PaymentMethod.Enum.check,
  PaymentMethod.Enum.phone,
]);
export type TrackedPayoffAllowedPaymentMethods = z.infer<typeof TrackedPayoffAllowedPaymentMethods>;

/*
  Outstanding amounts due and balances may have changed since the original payoff was generated,
  so this is a record of those amounts at time of recording.

  Note: the balances are from time of recording and do NOT account for any waivers
*/
export const PayoffWaiver = z.object({ escrow: z.number(), suspense: z.number() });
export type PayoffWaiver = z.infer<typeof PayoffWaiver>;

export const ShortageActionType = z.enum(['waive-suspense', 'waive-escrow']);
export type ShortageActionType = z.infer<typeof ShortageActionType>;

export const PaymentDueTotalsSnapshot = z.object({
  principal: z.number(),
  interest: z.number(),
  escrow: z.number(),
  reserveCredit: z.number(),
  escrowShortage: z.number(),
});
export type PaymentDueTotalsSnapshot = z.infer<typeof PaymentDueTotalsSnapshot>;

const PayoffRecord = z.object({
  createdAt: zodDateOrString, // Date the lender records the payment
  processedDate: zodDateOrString, // Date lender selects as the "Date received"
  paymentMethod: TrackedPayoffAllowedPaymentMethods,
  outstandingPrincipal: z.number(),
  outstandingInterest: z.number(),
  interestAttribution: InterestAttribution.array().optional(),
  fee: Fee.optional(),
  escrowBalance: z.number(),
  suspenseBalance: z.number(),
  outstandingFeeAmount: z.number(), // Does not include payoff fees which cannot be edited from original record
  outstandingAdvanceAmount: z.number(), // advance principal amount remaining
  outstandingAdvanceInterestAmount: z.number(),
  reserveBalance: z.number(),
  totalDue: z.number(), // Includes outstanding fees + advances
  amountPaid: z.number(),
  waivedAmounts: PayoffWaiver,
  additionalPrincipal: z.number().optional(),
  additionalEscrow: z.number().optional(),
  additionalSuspense: z.number().optional(),
  unfulfilledPaymentDueTotalsAtTimeOfPayoff: PaymentDueTotalsSnapshot.optional(), // Should always be present on new payoffs, may not be present before approx February 2025
});
export type PayoffRecord = z.infer<typeof PayoffRecord>;

const PayoffStatusPending = z.literal('pending');
const PayoffStatusExpired = z.literal('expired');
const PayoffStatusProcessed = z.literal('processed');
const PayoffStatusReversed = z.literal('reversed');
export const PayoffStatus = z.union([
  PayoffStatusPending,
  PayoffStatusExpired,
  PayoffStatusProcessed,
  PayoffStatusReversed,
]);
export type PayoffStatus = z.infer<typeof PayoffStatus>;

// Outstanding amounts due + balances at time of generation
const BasePayoff = z.object({
  id: zodBrandedUuid<PayoffId>(),
  createdAt: zodDateOrString,
  expirationDate: zodDateOrString,
  wireSettingsId: zodBrandedUuid<CompanyWireSettingsId>().optional(),
  outstandingPrincipal: z.number(),
  outstandingInterest: z.number(),
  interestAttribution: InterestAttribution.array().optional(),
  fee: Fee.optional(), // Payoff-specific fees
  outstandingFeeAmount: z.number(), // Fees already on the loan
  outstandingAdvanceAmount: z.number(), // Advances already on the loan
  escrowBalance: z.number(),
  suspenseBalance: z.number(),
  reserveBalance: z.number(),
  totalDue: z.number(),
  isPartial: z.boolean().optional(),
  propertyIds: z.array(zodBrandedUuid<PropertyId>()).optional(),
  status: z.union([PayoffStatusPending, PayoffStatusExpired]),
  interestPaidCredit: z.number().optional(),
});
export type BasePayoff = z.infer<typeof BasePayoff>;

// A processed record is added to the original payoff after being recorded
export const ProcessedPayoff = BasePayoff.extend({
  status: PayoffStatusProcessed,
  processedRecord: PayoffRecord,
});
export type ProcessedPayoff = z.infer<typeof ProcessedPayoff>;

export const ReversedPayoff = BasePayoff.extend({
  status: PayoffStatusReversed,
  processedRecord: PayoffRecord,
});
export type ReversedPayoff = z.infer<typeof ReversedPayoff>;

export function isProcessedPayoff(payoff: Payoff): payoff is ProcessedPayoff {
  return payoff.status === 'processed';
}
export function isProcessedFullPayoff(payoff: Payoff): payoff is ProcessedPayoff {
  return isProcessedPayoff(payoff) && !payoff.isPartial;
}
export function isProcessedPartialPayoff(payoff: Payoff): payoff is ProcessedPayoff {
  return isProcessedPayoff(payoff) && !!payoff.isPartial;
}

export const Payoff = z.union([BasePayoff, ProcessedPayoff, ReversedPayoff]);
export type Payoff = z.infer<typeof Payoff>;

export const PayoffAllowedStatuses = z.union([
  LoanStatusPreActive,
  LoanStatusActive,
  LoanStatusPendingTransfer,
  LoanStatusForeclosure,
]);

export const PaidOffType = z.union([z.literal('untrackedPayoff'), z.literal('payoff')]);
export type PaidOffType = z.infer<typeof PaidOffType>;

export const PaidOffRecord = z.object({
  id: zodBrandedUuid<PayoffId>(),
  payoffDate: zodDateOrString,
  payoffType: PaidOffType,
});
export type PaidOffRecord = z.infer<typeof PaidOffRecord>;

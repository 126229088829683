import { z } from 'zod';

import { EscrowCompanyId, EscrowSettingId } from '../BrandedIds';
import { zodBrandedUuid } from '../utils/Zod';
import { BaseLoanIdSideEffect } from './BaseLoanIdSideEffect';

export const GenerateAndMaybeSendMortgageeLetterSideEffectContext = BaseLoanIdSideEffect.extend({
  escrowCompanyId: z.union([zodBrandedUuid<EscrowCompanyId>(), zodBrandedUuid<EscrowSettingId>()]),
});

export const GenerateAndMaybeSendMortgageeLetterSideEffect =
  GenerateAndMaybeSendMortgageeLetterSideEffectContext.extend({
    type: z.literal('generateAndMaybeSendMortgageeLetter'),
  });
export type GenerateAndMaybeSendMortgageeLetterSideEffect = z.infer<
  typeof GenerateAndMaybeSendMortgageeLetterSideEffect
>;

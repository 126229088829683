/**
 * Overrides the company fee settings
 */

import { z } from 'zod';

import { NonNegativeMonetaryValue } from '../validations/fields';

export const PrepaymentPenaltyPlanType = z.enum(['percent', 'fixed']);
export type PrepaymentPenaltyPlanType = z.infer<typeof PrepaymentPenaltyPlanType>;

export const LoanPrepaymentPenaltyFee = z.object({
  flag: z.boolean(), // indicates whether to override the company setting
  expirationMonths: z.number().positive().int().optional(),
  planDescription: z.string().optional(),
  planType: PrepaymentPenaltyPlanType.optional(),
});
export type LoanPrepaymentPenaltyFee = z.infer<typeof LoanPrepaymentPenaltyFee>;

export const LoanLateFeeType = z.enum(['percent', 'fixed']);
export type LoanLateFeeType = z.infer<typeof LoanLateFeeType>;

export const LoanLateFeePercent = z.object({
  type: z.literal(LoanLateFeeType.enum.percent),
  decimalCharge: z.number().nonnegative(),
  minAmount: NonNegativeMonetaryValue.optional(),
  maxAmount: NonNegativeMonetaryValue.optional(),
});
export type LoanLateFeePercent = z.infer<typeof LoanLateFeePercent>;

export const LoanLateFeeFixed = z.object({
  type: z.literal(LoanLateFeeType.enum.fixed),
  amount: NonNegativeMonetaryValue,
});
export type LoanLateFeeFixed = z.infer<typeof LoanLateFeeFixed>;

// TODO followup delete
const LoanLateFeeOutdated = z.object({
  type: LoanLateFeeType.optional(),
  decimalCharge: z.number().nonnegative().optional(),
  minAmount: NonNegativeMonetaryValue.optional(),
  maxAmount: NonNegativeMonetaryValue.optional(),
});

export const LoanLateFee = z.discriminatedUnion('type', [LoanLateFeePercent, LoanLateFeeFixed]);
export type LoanLateFee = z.infer<typeof LoanLateFee>;

/**
 * Overrides company fee settings
 */
export const LoanFees = z.object({
  late: z.number().nonnegative().optional(), // TODO followup delete
  lateCharge: LoanLateFeeOutdated.optional(), // TODO follow delete. this is already being used in prod w/ non-union def. easier to migrate to lateFee
  lateFee: LoanLateFee.optional(), // TODO followup rename late
  prepaymentPenalty: LoanPrepaymentPenaltyFee.optional(),
});
export type LoanFees = z.infer<typeof LoanFees>;
